/* eslint-disable max-lines */
import React, { useState } from 'react';

import { getLinks } from '@cache/index';
import huSiteLogo from '@images/atalon-logo.svg';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { styled } from '@mui/system';
import { useIllustration } from '@utils/illustrations';
import Image from 'next/image';

import IconButton from '@ui/IconButton';
import IconButtonLink from '@ui/link/IconButtonLink';
import Link from '@ui/link/Link';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';

import EmbedPlayerDetailModal from './EmbedPlayerDetailModal';

const Meta = styled('div')(({ theme }) => ({
    fontSize: 12,
    display: 'flex',
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    marginRight: theme.spacing(1),
    '& p': {
        margin: 0,
        padding: 0,
    },
    alignItems: 'flex-start',
    [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(0),
    },
    [theme.breakpoints.up('md')]: {
        width: 216,
        marginTop: theme.spacing(0),
        marginBottom: theme.spacing(0),
    },
}));

const ImageContainer = styled('div')(({ theme }) => ({
    display: 'block',
    position: 'relative',
    width: 75,
    minWidth: 75,
    height: 75,
    [theme.breakpoints.up('sm')]: {
        width: 144,
        minWidth: 144,
        height: 144,
    },
    [theme.breakpoints.up('md')]: {
        width: 196,
        minWidth: 196,
        height: 196,
    },
}));

const MetaContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'promoBoxPlayer',
})(({ theme, promoBoxPlayer }) => ({
    position: 'relative',
    paddingTop: promoBoxPlayer ? 0 : 61,
    paddingBottom: promoBoxPlayer ? 0 : theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
        paddingBottom: theme.spacing(1),
        paddingTop: theme.spacing(1),
    },
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(0),
        paddingTop: theme.spacing(0),
    },
}));

const Credential = styled('div')(({ theme }) => ({
    position: 'fixed',
    top: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
        left: theme.spacing(2),
    },
    [theme.breakpoints.up('sm')]: {
        right: theme.spacing(2),
    },
}));

const ImageStyled = styled(Image)({
    borderRadius: 5,
    position: 'relative',
    overflow: 'hidden',
});

const Texts = styled('div')(({ theme }) => ({
    marginLeft: 10,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        fontSize: 18,
        width: 'calc(100vw - 350px)',
    },
    [theme.breakpoints.up('md')]: {
        width: 'calc(100vw - 475px)',
        position: 'absolute',
        left: 214,
        top: theme.spacing(1),
        right: 0,
        bottom: 50,
    },
}));

const Title = styled('p')(({ theme }) => ({
    fontWeight: 700,
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    display: '-webkit-box',
    [theme.breakpoints.up('sm')]: {
        fontSize: 25,
    },
}));

const Author = styled('p')(({ theme }) => ({
    color: theme.palette.text.secondary,
}));

const CloseButton = styled(IconButton, {
    shouldForwardProp: prop => prop !== 'promoBoxPlayer',
})(({ theme, promoBoxPlayer }) => ({
    [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: promoBoxPlayer ? 12 : theme.spacing(2),
        right: promoBoxPlayer ? theme.spacing(1) : theme.spacing(2),
    },
}));

const EmbedPlayerMeta = ({ track, promoBoxPlayer = false }) => {
    const { podcastImage } = useIllustration();
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const cms = useCms();

    return (
        <MetaContainer promoBoxPlayer={promoBoxPlayer}>
            {!promoBoxPlayer && (
                <Meta>
                    <Link color="inherit" underline="none" href={`/${track?.slug}`} target="_blank">
                        <ImageContainer>
                            <ImageStyled
                                src={track?.image || track?.parentMedia?.image || podcastImage}
                                alt={track?.title}
                                layout="fill"
                                objectFit="cover"
                            />
                        </ImageContainer>
                    </Link>
                    <Texts>
                        <Title>{track?.title}</Title>
                        <Author>{track?.parentMedia?.title}</Author>
                    </Texts>
                </Meta>
            )}
            <Credential>
                {!promoBoxPlayer && (
                    <>
                        <IconButtonLink
                            color="inherit"
                            edge="start"
                            size="small"
                            to={{ pathname: getLinks('home') }}
                            target="_blank"
                        >
                            <Image src={huSiteLogo} alt={cms[7]} />
                        </IconButtonLink>
                        <Typography variant="h6" noWrap component="div" sx={{ marginX: 1 }}>
                            {cms[7]}
                        </Typography>
                    </>
                )}
                <CloseButton
                    color="inherit"
                    edge="start"
                    size="small"
                    onClick={handleOpen}
                    promoBoxPlayer={promoBoxPlayer}
                >
                    <MoreVertIcon />
                </CloseButton>
            </Credential>
            <EmbedPlayerDetailModal open={open} onClose={handleClose} />
        </MetaContainer>
    );
};

export default EmbedPlayerMeta;
