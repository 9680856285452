import React from 'react';

import { getLinks } from '@cache/index';
import huSiteLogo from '@images/atalon-logo.svg';
import ModalUnstyled from '@mui/base/ModalUnstyled';
import CloseIcon from '@mui/icons-material/Close';
import { styled } from '@mui/system';
import Image from 'next/image';

import Box from '@ui/Box';
import IconButton from '@ui/IconButton';
import IconButtonLink from '@ui/link/IconButtonLink';
import Typography from '@ui/Typography';

import useCms from '@components/Cms/useCms';
import StaticPageLinks from '@components/page/Sidebar/StaticPageLinks';

const Modal = styled(ModalUnstyled)(({ theme }) => ({
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    height: '100%',
    width: '100%',
    zIndex: 6000,
    background: theme.palette.background.backdrop,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: theme.shape.borderRadius,
}));

const ModalContainer = styled(Box)(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(2),
}));

const SiteLogo = styled(Box)({
    margin: -1,
    display: 'flex',
    alignItems: 'center',
});

const Details = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    li: {
        paddingLeft: 0,
    },
    [theme.breakpoints.up('sm')]: {
        position: 'absolute',
        bottom: theme.spacing(2),
        left: theme.spacing(2),
        right: theme.spacing(2),
        li: {
            display: 'inline',
        },
    },
}));

const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(2),
    right: theme.spacing(2),
}));

const EmbedPlayerDetailModal = ({ open, onClose }) => {
    const cms = useCms();
    return (
        <Modal
            open={open}
            onClose={onClose}
            aria-labelledby="modal-details-title"
            aria-describedby="modal-details-description"
        >
            <ModalContainer>
                <Typography id="modal-details-title" variant="h6" component="h2">
                    <SiteLogo>
                        <IconButtonLink
                            color="inherit"
                            edge="start"
                            size="small"
                            to={{ pathname: getLinks('home') }}
                            target="_blank"
                        >
                            <Image src={huSiteLogo} alt={cms[7]} />
                        </IconButtonLink>
                        <Typography variant="h6" noWrap component="div" sx={{ marginX: 1 }}>
                            {cms[7]}
                        </Typography>
                    </SiteLogo>
                </Typography>
                <Details id="modal-details-description">
                    <StaticPageLinks target="_blank" />
                </Details>
                <CloseButton color="inherit" edge="start" size="small" onClick={onClose}>
                    <CloseIcon />
                </CloseButton>
            </ModalContainer>
        </Modal>
    );
};

export default EmbedPlayerDetailModal;
