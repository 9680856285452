import React from 'react';

import { getTodayDateString } from '@utils/date';
import Script from 'next/script';

const AdBannerScript = () => {
    const today = getTodayDateString();
    return (
        <Script id="atmediabanner" src={`https://cdn.atmedia.hu/atalon.hu.js?v=${today}`} strategy="afterInteractive" />
    );
};

export default AdBannerScript;
