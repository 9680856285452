import React from 'react';

import logo from '@images/atalon-logo-simple.svg';
import LockIcon from '@mui/icons-material/Lock';
import { tooltipClasses } from '@mui/material/Tooltip';
import { alpha, styled } from '@mui/system';
import { imageLoader } from '@utils/asset';
import Image from 'next/image';

import Tooltip from '@ui/Tooltip';

import useCms from '@components/Cms/useCms';

const CoverContainer = styled('div', {
    shouldForwardProp: prop => prop !== 'type' && prop !== 'extraShadow' && prop !== 'extended',
})(({ theme, type, extraShadow, extended }) => ({
    position: 'relative',
    minWidth: '100%',
    overflow: 'hidden',
    transition: 'all 0.2s ease',
    boxShadow: extraShadow ? theme.shadows[10] : '0 2px 3px rgba(0,0,0,0.1)',
    borderRadius: extended ? 0 : type === 'host' ? '50%' : theme.shape.borderRadius,
    '&:after': {
        content: '""',
        display: 'block',
        paddingBottom: '100%',
    },
}));

const ProgressBarContainer = styled('span')({
    display: 'block',
    position: 'absolute',
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 5,
    padding: 0,
    backgroundColor: 'rgba(0,0,0,0.2)',
});

const ProgressBar = styled('span', {
    shouldForwardProp: prop => prop !== 'completeness',
})(({ theme, completeness }) => ({
    display: 'block',
    height: 3,
    borderRadius: 1,
    background: theme.palette.primary.gradient,
    width: `${completeness * 100}%`,
}));

const StyledImage = styled(Image)({
    position: 'relative',
    overflow: 'hidden',
});

export const ImageGradient = styled('div', {
    shouldForwardProp: prop => prop !== 'isLocked',
})(({ theme, isLocked }) => ({
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transition: 'all 0.2s ease',
    ...(!isLocked
        ? {
              background: alpha(theme.palette.background.default, 0.1),
          }
        : {
              backdropFilter: 'grayscale(100%)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: alpha(theme.palette.background.default, 0.2),
          }),
}));

const ExclusiveBadgeContainer = styled('div')(({ theme }) => ({
    padding: theme.spacing(1),
    background: theme.palette.primary.gradient,
    position: 'absolute',
    top: 0,
    left: 0,
    zIndex: 5,
    borderBottomRightRadius: theme.shape.borderRadius,
    width: theme.spacing(4),
    height: theme.spacing(4),
}));

const ExclusiveBadge = styled('div')({
    position: 'relative',
    width: '100%',
    height: '100%',
    lineHeight: 0,
});

const ExclusiveTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} arrow placement="right" classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.primary.main,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.default,
    },
}));

const ExclusiveImage = styled(Image)({});

const MediaCardCover = ({
    url,
    type,
    extraShadow = false,
    completeness,
    alt = '',
    isExclusive,
    extended,
    isLocked,
}) => {
    const cms = useCms();
    return (
        <CoverContainer className="media-cover" type={type} extraShadow={extraShadow} extended={extended}>
            <StyledImage src={url} alt={alt} layout="fill" objectFit="cover" loader={imageLoader} />
            <ImageGradient className="media-cover-gradient" isLocked={isLocked}>
                {isLocked && <LockIcon sx={{ fontSize: 48 }} color="primary" />}
            </ImageGradient>
            {!!completeness && (
                <ProgressBarContainer>
                    <ProgressBar completeness={completeness} />
                </ProgressBarContainer>
            )}
            {isExclusive && isExclusive === 'true' && (
                <ExclusiveTooltip title={cms[642]}>
                    <ExclusiveBadgeContainer>
                        <ExclusiveBadge>
                            <ExclusiveImage src={logo} layout="fill" objectFit="cover" />
                        </ExclusiveBadge>
                    </ExclusiveBadgeContainer>
                </ExclusiveTooltip>
            )}
        </CoverContainer>
    );
};

export default MediaCardCover;
