/* eslint-disable max-lines */
import React, { useState } from 'react';

import atalonLogo from '@images/atalon-logo.svg';
import MenuIcon from '@mui/icons-material/Menu';
import { styled } from '@mui/system';
import { getLinks } from 'cache';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import PropTypes from 'prop-types';

import AppBar from '@ui/AppBar';
import Box from '@ui/Box';
import IconButton from '@ui/IconButton';
import IconButtonLink from '@ui/link/IconButtonLink';
import { NextLinkComposed } from '@ui/link/Link';
import Toolbar from '@ui/Toolbar';
import Typography from '@ui/Typography';

import AudioUploadSnackbar from '@components/admin/AudioUploadSnackbar/AudioUploadSnackbar';
// import AppStoreBadges from '@components/AppStoreBadges/AppStoreBadge';
import Ghosting from '@components/auth/Ghosting';
import useCms from '@components/Cms/useCms';
import useUser from '@components/User/useUser';

import Search from './Search';
import Sidebar from './Sidebar';
import AdminSidebar from './Sidebar/AdminSideBar';
import SidebarPlayerPlaceholder from './Sidebar/SidebarPlayerPlaceholder';
import StaticPageLinks from './Sidebar/StaticPageLinks';
import UserMenu from './Sidebar/UserMenu';
import UserSideBar from './Sidebar/UserSideBar';

const SearchResults = dynamic(import('./SearchResults'));

const drawerWidth = 240;

const Content = styled(Box)(({ theme }) => ({
    flexGrow: 1,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: '100px',
    backgroundImage: `url('https://assets.podpad.hu/55258243c28047d3baaeca0b46dca5fa6b9a7215f4ea4a0596f91adf41d82b9d6d2f01701f8f4451a53d5bf03c4035b4/3fc80afaae5f409ca8f170582e3fbd1b')`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom right',
    backgroundSize: '100% 300px',
    [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
    },
}));

const Layout = ({ children, isAdminPage }) => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { isAdmin } = useUser();
    const cms = useCms();

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const renderSideBar = isAdminPage ? (
        <AdminSidebar onClose={() => setMobileOpen(false)} />
    ) : (
        <UserSideBar onClose={() => setMobileOpen(false)} />
    );

    const handleSearch = value => {
        setSearchValue(value);
    };

    return (
        <Box sx={{ display: 'flex', minHeight: '100vh' }}>
            <Ghosting />
            <AppBar
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                }}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ display: { md: 'none' } }}
                        size="large"
                    >
                        <MenuIcon />
                    </IconButton>
                    <IconButtonLink
                        color="inherit"
                        edge="start"
                        size="large"
                        to={{ pathname: getLinks('home') }}
                        sx={{ display: { md: 'none' } }}
                    >
                        <Image src={atalonLogo} alt={cms[7]} />
                    </IconButtonLink>
                    <Search onSearch={handleSearch} />
                    <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end' }}>
                        <UserMenu onClose={() => setMobileOpen(false)} />
                    </Box>
                </Toolbar>
            </AppBar>
            <Sidebar drawerWidth={drawerWidth} mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle}>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'space-between', flexDirection: 'column' }}>
                    <Box>
                        <Toolbar sx={{ mb: 2, justifyContent: 'center' }}>
                            <Box
                                component={NextLinkComposed}
                                to={{ pathname: getLinks('home') }}
                                sx={{ display: 'flex', justifyContent: 'center', gap: 1, textDecoration: 'none' }}
                            >
                                <Image src={atalonLogo} sx={{ width: '32px', height: '32px' }} alt={cms[7]} />
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    sx={theme => ({ fontWeight: 400, color: theme.palette.primary.main })}
                                >
                                    {cms[7]}
                                </Typography>
                            </Box>
                        </Toolbar>
                        {renderSideBar}
                    </Box>
                    <Box>
                        {/* <AppStoreBadges /> */}
                        <StaticPageLinks onClose={() => setMobileOpen(false)} />
                        <SidebarPlayerPlaceholder />
                    </Box>
                </Box>
            </Sidebar>
            <Content component="main">
                <Toolbar sx={{ mb: 3 }} />
                {searchValue ? <SearchResults searchValue={searchValue} /> : children}
            </Content>
            {isAdmin && <AudioUploadSnackbar />}
        </Box>
    );
};

Layout.propTypes = {
    isAdminPage: PropTypes.bool,
};

export default Layout;
